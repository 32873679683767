import { Text } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import { navigate } from 'gatsby-link'
import React, { useEffect } from 'react'
import { doc, getDoc } from 'firebase/firestore';
import { useSessionStorage } from 'react-use';
import { isArray, isEqual } from 'lodash';
// import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import { httpsCallable } from '@firebase/functions';

import FullpageVstack from '../../components/FullpageVstack'
import useAnswers from './useAnswers'
import withFirestore from '../../utils/withFirestore';
import useScores from './useScores';
import withFunctions from '../../utils/withFunctions';
import { REF_KEY } from '../../containers/UtmRecorder';

const isSame = (a, b) => {
  if (isArray(a) && isArray(b)) return a.join() === b.join()
  return isEqual(a, b)
}

const isSameAnswer = (a, b) => {
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  return aKeys.length === bKeys.length && aKeys.every(key => isSame(a[key], b[key]))
}

const Calculate = ({ pageContext, firestore: db, functions }) => {
  const [iter] = useSessionStorage(`superquiz:love-languages:iter`)
  const [savedId, setSavedId] = useSessionStorage(`superquiz:love-languages:${pageContext.id}:${iter}:savedId`)
  const saveData = functions && httpsCallable(functions, 'saveFirestoreData')
  const [utm] = useSessionStorage(REF_KEY)
  const answers = useAnswers(pageContext.id)
  const scores = useScores(answers)
  useEffect(() => {
    const go = (firestoreId) => {
      const state = {
        scores,
        firestoreId,
        relation: answers.relation,
        invitationId: answers.invitationId,
        mode: pageContext.quizMode,
        email: answers.email,
      }
      let nextPath = 'result'
      if (answers.invitationId) nextPath = 'dual/accept'
      navigate(`/q/love-languages/${pageContext.id}/${nextPath}/`, { state })
    }
    const task = async () => {
      const { email, agree, ...restAns } = answers
      if (email) {
        restAns.email = email
      }
      if (savedId) {
        const docRef = doc(db, "answers", savedId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          if (isSameAnswer(docSnap.data().answers, restAns)) {
            return setTimeout(() => go(savedId), 1000)
          }
        }
      }

      const toSave = {
        id: 'love-languages',
        type: pageContext.id,
        answers: restAns,
      }
      if (pageContext.quizMode) {
        toSave.mode = pageContext.quizMode
      }
      try {
        const { data: id } = await saveData({
          collection: 'answers',
          ...toSave,
          utm,
        })
        setSavedId(id)
        go(id)
      } catch (e) {
        console.error(e)
      }
    }

    task()
  }, [scores, pageContext.id])
  return (
    <FullpageVstack mx="auto">
      <Spinner color="love.primary" />
      <Text>愛之語報告產生中...</Text>
    </FullpageVstack>
  )
}

export default compose(withFunctions, withFirestore)(Calculate)

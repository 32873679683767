import { VStack } from '@chakra-ui/layout'
import React from 'react'
import FullpageHeight from './FullpageHeight'

const FullpageVstack = ({ maxWidth, maxW, ...props }) => {
  return (
    <FullpageHeight maxWidth={maxWidth} maxW={maxW}>
      <VStack
        flex="1"
        py="1em"
        spacing="1em"
        justifyContent="center"
        {...props}
      />
    </FullpageHeight>
  )
}

FullpageVstack.defaultProps = {
  maxWidth: 'container.sm',
}

export default FullpageVstack

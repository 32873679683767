import React from 'react'
import { useFirestore } from 'reactfire';

const WithFirestore = ({ FsSubcomp, ...props }) => {
  const firestore = useFirestore()
  return <FsSubcomp {...props} firestore={firestore} />
}

const withFirestore = (FsSubcomp) => typeof window === 'undefined' ? FsSubcomp : (props => <WithFirestore {...props} FsSubcomp={FsSubcomp} />)

export default withFirestore

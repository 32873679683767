import { useMemo } from 'react'

const useScores = (answers) => {
  return useMemo(() => answers?.a?.reduce((s, a) => {
    const [g] = a.split('.')
    s[g] = s[g] || 0
    s[g] += 1
    return s
  }, {}), [answers])
}

export default useScores
